import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { v1 as uuid } from 'uuid';

import ResultTableGPR from '../../../components/GPR/ResultTableGPR/ResultTableGPR.component';
import ResultTableDownload from '../../../components/GPR/ResultTableDownload/ResultTableDownload.component';
import ResultTablePrint from '../../../components/GPR/ResultTablePrint/ResultTablePrint.component';
import { updateReportId } from '../../../store/actions';

import './ResultGPR.scss';
import { IRootState, IGPRReportData } from '../../../constants/types';
import { ENVS } from '../../../constants/constants';

import background from '../../../assets/images/gpr/background.png';
import print from '../../../assets/images/gpr/result/print.svg';
import save from '../../../assets/images/gpr/result/save.svg';
import spiner from '../../../assets/images/gpr/orange_spiner.gif';

const ResultGPR = () => {
  const [isLoading, setIsLodaing] = useState(false);
  const [data, setData] = useState<IGPRReportData | undefined>(undefined);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const user = useSelector((state: IRootState) => state.authReducer);

  useEffect(() => {
    setIsLodaing(true);
    const fetchData = async () => {
      const response = await fetch(`${ENVS.PROD}/gpr/findGpr?id=${user.apiKey}&reportId=${id}`);
      const data = await response.json();
      return data;
    };
    fetchData()
      .then((res) => {
        setData(res);
        setIsLodaing(false);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const formatNumber = (number) => number?.toLocaleString().split(/\s/).join(',');

  const generateTitle = () => {
    let date = new Date();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let year = date.getFullYear();
    let hour = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');
    return `GPR_${year}${month}${day}_${hour}${minute}${second}`;
  };

  const handleDownloadCSV = () => {
    if (data === undefined) {
      return;
    }

    const dataToParse = data.data.map((item) => {
      if (data.reportType === 'single') {
        return {
          address: item.address,
          bedrooms: item.bedrooms,
          bathrooms: item.bathrooms,
          current_rent: item.currentRent,
          avarage_rent: item.mean,
          url: item.quickview_url
        };
      } else {
        return {
          address: item.address,
          unit_description: item.description,
          bedrooms: item.bedrooms,
          bathrooms: item.bathrooms,
          rent: item.mean,
          number_of_units: item.count,
          total_rent: item.mean * item.count,
          url: item.quickview_url
        };
      }
    });

    const totalToParse =
      data.reportType === 'single'
        ? {
            address: 'Total',
            bedrooms: data.totalBedrooms,
            bathrooms: '',
            current_rent: data.totalCurrentRent,
            avarage_rent: data.totalAvarageRent
          }
        : {
            address: 'Total',
            unit_description: '',
            bedrooms: data.totalBedrooms,
            bathrooms: '',
            rent: data.totalAvarageRent,
            number_of_units: data.totalCount,
            total_rent: data.multiTotalRent
          };

    const csv = Papa.unparse([...dataToParse, totalToParse]);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${generateTitle()}.csv`);
  };

  const hendleRecalculate = () => {
    if (data) {
      const newId = uuid();
      const activeReportType = data.reportType;
      dispatch(updateReportId({ activeReportType, newId }));
      navigate('/gpr-calculator/report');
    }
  };

  return (
    <div className="container" style={{ backgroundImage: `url(${background})` }}>
      <div className="_container">
        <div className="result__header">
          <div className="result__title">Rentometer GPR Calculation Results</div>
          <div className="result__items">
            <button className="result__item" onClick={handleDownloadCSV}>
              <img src={save} alt="save icon" />
              <div className="result__item-text">Download CSV</div>
            </button>
            <ResultTableDownload reportData={data} />
            <button className="result__item" onClick={handlePrint}>
              <img src={print} alt="print icon" />
              <div className="result__item-text">Print</div>
              <div style={{ display: 'none' }}>
                <ResultTablePrint ref={componentRef} reportData={data} />
              </div>
            </button>
          </div>
        </div>
        <div className="result__content">
          <div className="result__aside">
            <button className="aside__recalculate" onClick={hendleRecalculate}>
              Recalculate
            </button>
            <div className="aside__content">
              {isLoading ? (
                <div className="spiner-container">
                  <img src={spiner} alt="loading.." />
                </div>
              ) : (
                <>
                  <div className="aside__item">
                    <div className="aside__item_total">${formatNumber(data?.monthGPR)}</div>
                    <div className="aside__item_description">
                      Monthly Gross Potential Rent (GPR)
                    </div>
                  </div>
                  <div className="aside__item">
                    <div className="aside__item_total">
                      ${formatNumber(data?.estimatedPropertyValue)}
                    </div>
                    <div className="aside__item_description">
                      Estimated Property Value (1% rule)
                    </div>
                  </div>
                  <div className="aside__item">
                    <div className="aside__item_total">{data?.numberOfItems}</div>
                    <div className="aside__item_description">
                      Number of {data?.reportType === 'single' ? 'houses' : 'units'} calculated
                    </div>
                  </div>
                  <div className="aside__item">
                    <div className="aside__item_total">
                      ${formatNumber(data?.averageRentForItem)}
                    </div>
                    <div className="aside__item_description">
                      Average rent for one {data?.reportType === 'single' ? 'house' : 'unit'}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {isLoading ? (
            <div className="spiner-container">
              <img src={spiner} alt="loading.." />
            </div>
          ) : (
            <ResultTableGPR reportData={data} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultGPR;
